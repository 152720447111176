import React from "react"
import styled from "styled-components"
import Seo from "../components/Seo"
import Layout from "../components/Layout"

const Error = () => (
  <Layout>
    <Seo title="ERROR" />
    <Main>
      <section>
        <h1>404</h1>
        <h3>Page not found</h3>
      </section>
    </Main>
  </Layout>
)

export default Error

const Main = styled.main`
  height: 95vh;
  text-align: center;
  padding-top: 7rem;
  h1 {
    font-size: 6rem;
  }
  h3 {
    font-size: 2rem;
  }
`